import './App.css';
import Logo from './components/Logo';
import Linktree from './components/Linktree';

function App() {
  return (
    <div className="App">
        <header className="App-header">
            <Logo></Logo>
            <p>
                <code>Welcome to Loxive.com</code>
            </p>
        </header>
        <Linktree></Linktree>
        <p>
          <code>Book me: loxiveofficial@gmail.com</code>
        </p>
    </div>
  );
}

export default App;
